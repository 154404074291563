import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import HostGame from "./Pages/HostGame/HostGame";

import "./App.scss";
import Layout from "./Components/Layout/Layout";
import YourCards from "./Pages/YourCards/YourCards";
import YourTrophies from "./Pages/YourTrophies/YourTrophies";
import JoinGame from "./Pages/JoinGame/JoinGame";
import CardShop from "./Pages/CardShop/CardShop";
import ProfileSettings from "./Pages/ProfileSettings/ProfileSettings";
import AwaitingOpponent from "./Pages/AwaitingOpponent/AwaitingOpponent";
import GameBoard from "./Pages/GameBoard/GameBoard";
import MutantResearch from "./Pages/MutantResearch/MutantResearch";
import { Toaster } from 'react-hot-toast';

const App: React.FC = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/host-game" element={<HostGame />} />
		<Route path="/my-cards" element={<YourCards />} />
		<Route path="/my-career" element={<YourTrophies />} />
		<Route path="/join-game" element={<JoinGame />} />
		<Route path="/card-shop" element={<CardShop />} />
		<Route path="/profile-settings" element={<ProfileSettings />} />
		<Route path="/awaiting-opponent" element={<AwaitingOpponent />} />
		<Route path="/game-board" element={<GameBoard />} />
		<Route path="/mutant-research-center" element={<MutantResearch />} />
      </Routes>

      <Toaster
    position="bottom-right"
    toastOptions={{
      className: 'text-white',
      style: {
        border: '1px solid #707070',
        background: 'black',
        padding: '16px',
        color: '#707070',
      },
    }}/>


    </Layout>

  
  );
};

export default App;
