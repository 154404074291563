// src/features/navbar/navbarSlice.js
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  tier: string | undefined;
}

const initialState: ProfileState = {
  tier: undefined,
};

const profileSlide = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setUserTier(state, action: PayloadAction<any>) {
      state.tier = action.payload;
    },
    // Add other actions if needed
  },
});

export const { setUserTier } = profileSlide.actions;
export default profileSlide.reducer;
