import { ethers } from 'ethers';
import cardABI from './pico-card.json';
import shopABI from './cardshop.json';
import pico1v1ABI from './pico1v1.json';
import sfortABI from './sfort.json';
import stakingABI from './staking.json';
import vaultABI from './vault.json';
import grabberABI from './grabber.json';
import viewerABI from './viewer.json';


const addressMutantStaking = "0xcE8c44f865c747395BaD3790B61eE9AC15f4199f";
const addressPicoCard = "0x5Ef135F575d215AE5A09E7B30885E866db138aF6";
const addresssFort = "0x9e2e6c16803878c18e54ed74f05aeafcce464626";

const addressPico1v1 = "0x2CFd1d6a8D68A6d1efB401CfcbA6D5B022aAD82d";
const addressViewer = "0x8eC2280b6f328b1d20db9BaD817d8c329625F38B";

const addressCardShop = "0x433826d12Ae354bCad35603AA4AcBBfbe67FE25f";
const addressPicoVault = "0xeB4fFAA0DCf689B04ccbde2459A7B6d5A9DF5010";
const addressGrabber = "0x6B105427C243a9277172aeE01a37ABD0Ea97A57b";


let provider: ethers.providers.Web3Provider | undefined;
let signer: ethers.providers.JsonRpcSigner | undefined;
let cardContract: ethers.Contract | undefined;
let shopContract: ethers.Contract | undefined;
let pico1v1Contract: ethers.Contract | undefined;
let sfortContract: ethers.Contract | undefined;
let stakingContract: ethers.Contract | undefined;
let vaultContract: ethers.Contract | undefined;
let grabberContract: ethers.Contract | undefined;
let viewerContract: ethers.Contract | undefined;


if (typeof window !== 'undefined' && typeof window.ethereum !== 'undefined') {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
  cardContract = new ethers.Contract(addressPicoCard, cardABI, signer);
  shopContract = new ethers.Contract(addressCardShop, shopABI, signer);
  pico1v1Contract = new ethers.Contract(addressPico1v1, pico1v1ABI, signer);
  sfortContract = new ethers.Contract(addresssFort, sfortABI, signer);
  stakingContract = new ethers.Contract(addressMutantStaking, stakingABI, signer);
  vaultContract = new ethers.Contract(addressPicoVault, vaultABI, signer);
  grabberContract = new ethers.Contract(addressGrabber, grabberABI, signer);
  viewerContract = new ethers.Contract(addressViewer, viewerABI, signer);
  
} else {
  console.log('Ethereum object not found, make sure you have MetaMask installed.');
}

export {
  provider,
  signer,
  cardContract,
  shopContract,
  pico1v1Contract,
  sfortContract,
  stakingContract,
  vaultContract,
  grabberContract,
  viewerContract
};
