import React, { useState, useEffect, useCallback } from "react";
import Title from "../../../Components/Common/Title/Title";
import ToggleSwitch from "../../../Components/Common/UI/ToggleSwitch/ToggleSwitch";
import PrimaryButton from "../../../Components/Common/UI/PrimaryButton/PrimaryButton";
import { Link, useNavigate } from "react-router-dom";
import defaultAvatar from "../../../assets/images/profile-picture.png";
import { useWeb3 } from "../../../web3/Web3Context";
import cardMetadata from "../../../web3/cardMetadata.json";

import {
  cardContract,
  sfortContract,
  pico1v1Contract,
  grabberContract,
  viewerContract,
} from "../../../web3/contracts";
import { ethers } from "ethers";
import { notify } from "../../../utils/customToast";
import AllowancePopup from "../../../Components/Common/AllowancePopup/AllowancePopup";
import AllowanceModal from "../../../Components/Modals/AllowanceModal/AllowanceModal";
import { shortenAddress } from "../../../utils/shortenAddress";
import BorderStyle from "../../../Components/Common/BorderStyle/BorderStyle";
import InfoModal from "../../../Components/Common/Modals/InfoModal/InfoModal";
import Loader from "../../../Components/Common/Loader/Loader";
import { findValidProfilePicUrl } from "../../../utils/findValidProfilePicUrl";
import t1 from "../../../assets/images/badges/T1.png";
import t2 from "../../../assets/images/badges/T2.png";
import t3 from "../../../assets/images/badges/T3.png";
import { useSelector } from "react-redux";

type Props = {};

const JoinGameContainer: React.FC<Props> = () => {
  const { myAddress, isConnected } = useWeb3();
  const [isRulesConfirmed, setRulesConfirmed] = useState<boolean>(false);
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [balanceAllowance, setBalanceAllowance] = useState<string>("0");
  const [approveAmt, setApproveAmt] = useState<string>("0");
  const [showPopover, setShowPopover] = useState(false);
  const [ratioWarningContent, setRatioWarningContent] =
    useState<any>(undefined);
  const [balance, setBalance] = useState<string>("0");
  const userTier = useSelector((state: any) => state.profileSlide.tier);
  const [rooms, setRooms] = useState<
    {
      creator: string;
      roomId: string;
      roomBet: string;
      profile: { picture: string; name: string };
      tier: number;
    }[]
  >([]); // State to store live rooms with profiles
  const [warningIsOpen, setwarningIsOpen] = useState(false);
  const [rarityPercentages, setRarityPercentages] = useState<{
    rarity0: number;
    rarity1: number;
    rarity2: number;
  }>({ rarity0: 0, rarity1: 0, rarity2: 0 });
  const [characterCount, setCharacterCount] = useState(0);
  const [weaponCount, setWeaponCount] = useState(0);
  const [shieldCount, setShieldCount] = useState(0);
  const [totalRarity, setTotalRarity] = useState<any>(undefined);
  const [rarityTotalNumber, setRarityTotalNumber] = useState<any>(undefined);
  const navigate = useNavigate();

  const handleToggleChange = async (checked: boolean) => {
    if (!cardContract || !pico1v1Contract || !myAddress) return;

    try {
      setMainLoader(true);
      const tx = await cardContract.setApprovalForAll(
        pico1v1Contract.address,
        checked
      );
      await tx.wait();
      setIsApprovedForAll(checked);
      notify(
        checked
          ? "Approval granted successfully!"
          : "Approval revoked successfully!",
        "success"
      );
    } catch (error) {
      console.error("Error during setApprovalForAll:", error);
      notify(
        "An error occurred while changing approval status. Please try again.",
        "error"
      );
    } finally {
      setMainLoader(false);
    }
  };

  const onCloseAllowanceModal = () => {
    setIsOpen(false);
  };

  const fetchBalance = useCallback(async () => {
    if (!sfortContract || !myAddress) return;
    try {
      const balance = await sfortContract.balanceOf(myAddress);
      setBalance(
        Math.floor(Number(ethers.utils.formatEther(balance))).toString()
      );
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  }, [sfortContract, myAddress]);

  const getUserAllowance = useCallback(async () => {
    if (!sfortContract || !myAddress || !pico1v1Contract) return;
    setMainLoader(true);

    await fetchRarityPercentages();

    try {
      const allowance = await sfortContract.allowance(
        myAddress,
        pico1v1Contract.address
      );
      const formattedAllowance = ethers.utils.formatEther(allowance);
      setBalanceAllowance(
        Number(formattedAllowance) > 0 ? formattedAllowance : ""
      );
    } catch (e) {
      console.error("Error fetching allowance:", e);
    } finally {
      setMainLoader(false);
      setIsOpen(false);
    }
  }, [sfortContract, myAddress, pico1v1Contract]);

  const checkApprovalForAll = useCallback(async () => {
    if (!cardContract || !pico1v1Contract || !myAddress) return;
    try {
      const isApproved = await cardContract.isApprovedForAll(
        myAddress,
        pico1v1Contract.address
      );
      setIsApprovedForAll(isApproved);
    } catch (error) {
      console.error("Error checking approval status:", error);
    }
  }, [cardContract, pico1v1Contract, myAddress]);

  const fetchLiveRooms = useCallback(async () => {
    if (!pico1v1Contract) return;
    try {
      const liveroomcount = await pico1v1Contract?.getLiveRoomsLength();

      const [creators, liveroomids, roombets, tiers] =
        await viewerContract?.getLiveRoomsBatch(0, Number(liveroomcount));
      const liveRooms = await Promise.all(
        creators.map(async (creator: string, index: number) => {
          const profilePic = await findValidProfilePicUrl(creator);
          const username = await fetchUsername(creator);
          return {
            creator,
            roomId: liveroomids[index].toString(),
            roomBet: Number(roombets[index]),
            profile: {
              picture: profilePic || defaultAvatar,
              name: username || shortenAddress(creator),
            },
            tier: Number(tiers[index]),
          };
        })
      );
      setRooms(liveRooms);
    } catch (error) {
      console.error("Error fetching live rooms:", error);
    }
  }, [pico1v1Contract]);

  const fetchUsername = async (address: string): Promise<string | null> => {
    try {
      const response = await fetch(`/api/fetchUsername?address=${address}`);
      if (response.ok) {
        const data = await response.json();
        return data.username || null;
      } else {
        console.error("Failed to fetch username");
      }
    } catch (error) {
      console.error("Error fetching username:", error);
    }
    return null;
  };

  useEffect(() => {
    if (isConnected && myAddress) {
      getUserAllowance();
      fetchCardCounts();
      checkApprovalForAll();
      fetchBalance();
      fetchLiveRooms();
    }
  }, [isConnected, myAddress, checkApprovalForAll, fetchLiveRooms]);

  const joinRoom = async (roomId: string) => {
    if (totalRarity?.circles?.total > totalRarity?.circles?.max) {
      console.log("CIRCLE RARITY");
      setRatioWarningContent(
        <p className="cardsRatioInfoCircles">
          You have too many <span>founders</span> in your crypto army. You have{" "}
          <span> {rarityPercentages.rarity2}%</span> circle cards in your deck.
          No more then <span>33%</span> circles allowed.
        </p>
      );
      setwarningIsOpen(true);
    } else if (totalRarity?.squares?.total < totalRarity?.squares?.min) {
      console.log("CIRCLE RARITY");
      setRatioWarningContent(
        <p className="cardsRatioInfoSquares">
          Your <span>community</span> is too small. You have{" "}
          <span>{rarityPercentages.rarity0}%</span> sqaure cards in your deck.
          You can only play with <span>33%</span> squares.
        </p>
      );
      setwarningIsOpen(true);
    } else {
      if (!pico1v1Contract) return;
      setRatioWarningContent(undefined);
      try {
        setMainLoader(true);

        const balance = await cardContract?.balanceOf(myAddress);
        const cardCount = balance.toNumber();

        if (cardCount < 18) {
          setwarningIsOpen(true);
          return;
        }

        const estimatedGas = await pico1v1Contract?.estimateGas.joinRoom(
          roomId
        );
        const gasLimit = Math.floor(estimatedGas.toNumber() * 1.2);
        const overrides = { gasLimit: ethers.utils.hexlify(gasLimit) };
        const tx = await pico1v1Contract?.joinRoom(roomId, overrides);
        await tx.wait();

        notify("Joined the room successfully!", "success");
        navigate("/awaiting-opponent");
        setMainLoader(false);
      } catch (error) {
        console.error("Error joining room:", error);
        notify(
          "An error occurred while joining the room. Please try again.",
          "error"
        );
      } finally {
        setMainLoader(false);
      }
    }
  };

  const onApprove = async () => {
    if (!sfortContract || !pico1v1Contract) {
      notify("Smart contract is not available.", "error");
      return;
    }

    if (Number(approveAmt) <= 0) {
      notify("Please choose a valid allowance.", "error");
      return;
    }

    try {
      const amountBn = ethers.utils.parseUnits(approveAmt);
      setMainLoader(true);

      const tx = await sfortContract.approve(pico1v1Contract.address, amountBn);
      await tx.wait();

      notify("Allowance added successfully!", "success");
      getUserAllowance();
    } catch (error) {
      console.error("Error during approval:", error);
      notify("An error occurred. Please try again.", "error");
    } finally {
      setMainLoader(false);
    }
  };

  // Function to fetch and calculate rarity percentages
  const fetchRarityPercentages = async () => {
    const totalRarityCounts = { rarity0: 0, rarity1: 0, rarity2: 0 };
    let totalCards = 0;
    const balance = await cardContract?.balanceOf(myAddress); // Get the total balance of cards in the wallet

    const batches = Math.ceil(balance / 40); // Calculate how many batches of 40 cards to process

    // Loop through the batches and count rarities
    for (let i = 0; i < batches; i++) {
      const from = i * 40;
      const cardBatch = await grabberContract?.getCardsBatch(myAddress, from);

      const categoryIds = cardBatch[1]; // Assume the second array is the categoryIDs
      totalCards += categoryIds.length;

      // Loop through the categoryIds and fetch metadata for rarity
      for (const categoryId of categoryIds) {
        const metadata = cardMetadata.find(
          (meta: any) => meta.id === Number(categoryId)
        );

        if (metadata) {
          const rarity = metadata.rarity;

          // Increment the count for each rarity
          if (rarity === 0) {
            totalRarityCounts.rarity0++;
          } else if (rarity === 1) {
            totalRarityCounts.rarity1++;
          } else if (rarity === 2) {
            totalRarityCounts.rarity2++;
          }
        }
      }
    }

    setRarityTotalNumber(totalRarityCounts);

    // Calculate total number of cards
    const total =
      totalRarityCounts.rarity0 +
      totalRarityCounts.rarity1 +
      totalRarityCounts.rarity2;
    // Calculate minimum and maximum thresholds for rarity0 and rarity2 percentages
    const minSquaresPercentage = 33.333; // minimum 33%
    const maxCirclesPercentage = 33.333; // maximum less than 33%
    // Calculate the number of cards based on percentages
    const minSquaresCards = Math.ceil((minSquaresPercentage / 100) * total); // minimum 33% of total
    const maxCirclesCards = Math.floor((maxCirclesPercentage / 100) * total); // less than 33% of total

    // Calculate max allowed for rarity0 based on remaining cards after minRarity1 and maxRarity2
    const minTrianglesCards = Math.ceil((10 / 100) * total); // minimum 10% of total for rarity1
    // const minRarity2Cards = Math.ceil((10 / 100) * total); // minimum 10% of total for rarity2
    // const maxRarity0Cards = total - minRarity1Cards - minRarity2Cards;
    // Ensure rarity0 does not exceed 80% of total
    // const maxAllowedRarity0 = Math.floor((80 / 100) * total);
    // const finalMaxRarity0Cards = Math.min(maxRarity0Cards, maxAllowedRarity0);
    setTotalRarity({
      squares: displayRarities(
        "squares",
        totalRarityCounts.rarity0,
        minSquaresCards,
        total
      ),
      triangles: displayRarities(
        "triangles",
        totalRarityCounts.rarity1,
        minTrianglesCards,
        total
      ),
      circles: displayRarities(
        "circles",
        totalRarityCounts.rarity2,
        maxCirclesCards,
        total
      ),
    });

    // Calculate percentages
    const rarity0Percent = (totalRarityCounts.rarity0 / totalCards) * 100;
    const rarity1Percent = (totalRarityCounts.rarity1 / totalCards) * 100;
    const rarity2Percent = (totalRarityCounts.rarity2 / totalCards) * 100;

    setRarityPercentages({
      rarity0: parseFloat(rarity0Percent.toFixed(2)),
      rarity1: parseFloat(rarity1Percent.toFixed(2)),
      rarity2: parseFloat(rarity2Percent.toFixed(2)),
    });
  };

  function displayRarities(
    rarity: any,
    value: any,
    minRarity: any,
    total: any
  ) {
    if (rarity === "squares") {
      return {
        total: value,
        min: minRarity,
      };
    }
    if (rarity === "circles") {
      return {
        total: value,
        max: minRarity,
      };
    }
    return { total: value };
  }

  const fetchCardCounts = useCallback(async () => {
    if (myAddress && cardContract) {
      try {
        let characterCount = 0;
        let weaponCount = 0;
        let shieldCount = 0;

        const balance = await cardContract.balanceOf(myAddress);
        await fetchRarityPercentages();
        const tokenIds: any[] = [];
        if (balance && balance.gt(0)) {
          await getCardsBatch(tokenIds, 0, balance.toNumber());
        }

        tokenIds.forEach((card: any) => {
          const metadata = cardMetadata.find(
            (meta) => meta.id === card.cardType
          );
          if (metadata) {
            switch (metadata.type) {
              case 0:
                characterCount++;
                break;
              case 1:
                weaponCount++;
                break;
              case 2:
                shieldCount++;
                break;
            }
          }
        });

        setCharacterCount(characterCount);
        setWeaponCount(weaponCount);
        setShieldCount(shieldCount);
      } catch (e) {
        console.error("Failed to fetch card counts", e);
      }
    }
  }, [myAddress]);

  const getCardsBatch = async (
    tokenIds: any[],
    from: number,
    maxLength: number
  ) => {
    if (!cardContract) return;
    try {
      const countPerTrait = await grabberContract?.getCardsBatch(
        myAddress,
        from
      );
      for (let i = 0; i < countPerTrait[0].length; i++) {
        const cardType = Number(countPerTrait[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          tokenIds.push({
            id: countPerTrait[0][i],
            cardType: cardType,
            image: `/assets/images/cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error("Error fetching card batch", e);
    }
  };

  const checkCircleMaxCardsNumber = (cards: any) => {
    return cards?.circles?.total > cards?.circles?.max ? "error" : "";
  };
  const checkCircleMinCardsNumber = (cards: any) => {
    return cards?.squares?.total < cards?.squares?.min ? "error" : "";
  };

  const handleMouseEnter = () => {
    setShowPopover(true);
  };

  const handleMouseLeave = () => {
    setShowPopover(false);
  };
  console.log("rooms", rooms);
  return (
    <>
      <div className="joinGameContainer">
        <Title title="JOIN A ROOM" />
        <BorderStyle />
        <div className="subTitle">PLAY A 1 VS 1 PICO GAME</div>
        <div className="joinGameDescription">
          <p>
            Please make sure you first grant card access to the game, and be
            aware of the room fee, this fee will be redeemed only by the winning
            player.
          </p>
          <p className="cardsInfo">
            You own <b>{characterCount}</b> characters, <b>{weaponCount}</b>{" "}
            weapons, and <b>{shieldCount}</b> shields. Of which{" "}
            <span className={`${checkCircleMinCardsNumber(totalRarity)}`}>
              {rarityTotalNumber?.rarity0} / {totalRarity?.squares.min} squares,
            </span>{" "}
            <span>{rarityTotalNumber?.rarity1}</span> triangles,{" "}
            <span className={`${checkCircleMaxCardsNumber(totalRarity)}`}>
              {totalRarity?.circles?.total} / {totalRarity?.circles.max} circles
            </span>
          </p>
          {/* <div>
            <h1>Squares: {rarityPercentages.rarity0}%</h1>
            <h1>Triangles: {rarityPercentages.rarity1}%</h1>
            <h1>Circles: {rarityPercentages.rarity2}%</h1>
          </div> */}
        </div>
        <ToggleSwitch
          checked={isApprovedForAll}
          onChange={handleToggleChange}
          title="CARD ACCESS:"
        />

        <div className="joinGameList">
          {rooms.length > 0 ? (
            rooms.map((room, index) => (
              <div
                key={index}
                className="listItem"
                onClick={() => userTier <= room?.tier && joinRoom(room.roomId)}
              >
                <div className="avatar">
                  <img
                    src={room.profile.picture}
                    alt="avatar"
                    className="avatarImage"
                  />
                  {room?.tier === 11 ? (
                    <img src={t1} alt="Badge" className="badge" />
                  ) : room?.tier === 12 ? (
                    <img src={t2} alt="Badge" className="badge" />
                  ) : (
                    <img src={t3} alt="Badge" className="badge" />
                  )}
                </div>
                <div className="name">
                  <h3>{room.profile.name}</h3>
                  <span>Room ID: {room.roomId}</span>
                </div>
                <div className="joinButton">
                  <PrimaryButton
                    text="JOIN"
                    className="secondaryColor"
                    width="114px"
                  />
                </div>
                <div className="fee">
                  <span>FEE:</span>
                  <span>{room.roomBet} $FORT</span>
                </div>
                {userTier > room?.tier ? (
                  <div
                    className="roomOverlay"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    {showPopover && (
                      <div className="popover">
                        <p>You can't play with a weaker opponent</p>
                      </div>
                    )}
                  </div>
                ) : undefined}
              </div>
            ))
          ) : (
            <h1 className="text-center pt-4 pb-10 text-xl">
              No rooms are currently available. <br /> You can host a game.
            </h1>
          )}
        </div>
      </div>

      <div className="actionButtons">
        <Link to="/host-game">
          <PrimaryButton text="HOST GAME" />
        </Link>
        <Link to="/">
          <PrimaryButton text="Back" />
        </Link>
      </div>
      <AllowancePopup
        title="Game Room Allowance"
        balanceAllowance={Number(balanceAllowance)}
        onClickAdd={() => setIsOpen(true)}
      />
      <AllowanceModal
        open={modalIsOpen}
        onClose={onCloseAllowanceModal}
        userTokenBalance={Number(balance)}
        balance={Number(balance)}
        approveAmt={approveAmt}
        setApproveAmt={setApproveAmt}
        onApprove={onApprove}
      />
      <InfoModal
        openModal={warningIsOpen}
        closeModal={() => setwarningIsOpen(false)}
        handleBack={() => setwarningIsOpen(false)}
        title="YOU NEED A FULL DECK"
        description={
          ratioWarningContent
            ? ratioWarningContent
            : "To play PiCO you need a minimum of 18 cards. To complete your deck click on the card shop button below."
        }
      />
      {mainLoader && <Loader />}
    </>
  );
};

export default JoinGameContainer;
