import React, { useEffect, useState, useCallback } from "react";
import { useWeb3 } from "../../web3/Web3Context";
import Title from "../../Components/Common/Title/Title";
import squareIcon from "../../assets/images/pico-card-collection/square-cards-icon.png";
import triangleIcon from "../../assets/images/pico-card-collection/triangle-cards-icon.png";
import circleIcon from "../../assets/images/pico-card-collection/circle-cards-icon.png";
import rectangleIcon from "../../assets/images/pico-card-collection/rectangle-cards-icon.png";
import character from "../../assets/images/pico-card-collection/character-icon.png";
import weapon from "../../assets/images/pico-card-collection/weapon-icon.png";
import shield from "../../assets/images/pico-card-collection/shield-icon.png";
import {
  grabberContract,
  cardContract,
  pico1v1Contract,
} from "../../web3/contracts";
import "./YourCards.scss";
import { notify } from "../../utils/customToast";
import cardMetadata from "../../web3/cardMetadata.json";
import Modal from "../../Components/Common/Modal/Modal";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import CloseButton from "../../Components/Common/UI/PrimaryButton/CloseButton";
import t1 from "../../assets/images/badges/T1.png";
import t2 from "../../assets/images/badges/T2.png";
import t3 from "../../assets/images/badges/T3.png";
import ProgressDemo from "../../Components/Common/ProgressBar/ProgressBar";

type Props = {};
type Card = {
  id: number;
  cardType: number;
  image: string;
  name: string;
  rarity: number;
  cattype: number;
  strength: number;
};

const YourCards: React.FC<Props> = () => {
  const { myAddress, isConnected } = useWeb3();
  const [selectedType, setSelectedType] = useState<number>(-1);
  const [selectedRarity, setSelectedRarity] = useState<number>(-1);
  const [allData, setAllData] = useState<Card[]>([]);
  const [filteredData, setFilteredData] = useState<Card[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<Card | null>(null);
  const [transferCard, setTransferCard] = useState<boolean>(false);
  const [toAddress, setToAddress] = useState<string>("");
  const [circulatingSupply, setCirculatingSupply] = useState<string>("");
  const [burnedSupply, setBurnedSupply] = useState<string>("");
  const [tier, settier] = useState<number>(11);
  const [avgstrength, setavgstrength] = useState<number>(0);

  const filterCards = (rarity: number, type: number) => {
    let filtered = [...allData];

    if (rarity !== -1) {
      filtered = filtered.filter((card) => card.rarity === rarity);
    }

    if (type !== -1) {
      filtered = filtered.filter((card) => card.cattype === type);
    }

    setFilteredData(filtered);
  };

  const handleRarityClick = (rarity: number) => {
    let newRarity = selectedRarity === rarity ? -1 : rarity;

    if (newRarity === 3) {
      setSelectedType(-1);
    }

    setSelectedRarity(newRarity);
    filterCards(newRarity, selectedType);
  };

  const handleTypeClick = (type: number) => {
    let newType = selectedType === type ? -1 : type;

    if (newType !== -1 && selectedRarity === 3) {
      setSelectedRarity(-1);
      filterCards(-1, newType);
    } else {
      setSelectedType(newType);
      filterCards(selectedRarity, newType);
    }
  };

  const getAllCards = useCallback(async () => {
    try {
      setMainLoader(true);
      let tokenIds: Card[] = [];
      let balance = await cardContract?.balanceOf(myAddress);
      if (balance && Number(balance) > 0) {
        setTotal(Number(balance));
        await getCardsBatch(tokenIds, 0, Number(balance));
        setAllData(tokenIds);
        setFilteredData([...tokenIds]);
      }
      setMainLoader(false);

      const myavg = await pico1v1Contract?.getDeckStrength(myAddress);
      const mytier = await pico1v1Contract?.getDeckTier(myAddress);
      settier(Number(mytier));
      setavgstrength(Number(myavg));
    } catch (e) {
      notify("Failed to fetch balance", "error");
      console.error(e);
      setMainLoader(false);
    }
  }, [myAddress]);

  const getCardsBatch = async (
    tokenIds: Card[],
    from: number,
    maxLength: number
  ) => {
    try {
      let cardBatch = await grabberContract?.getCardsBatch(myAddress, from);

      for (let i = 0; i < cardBatch[0].length; i++) {
        const cardType = Number(cardBatch[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          const isMutant = cardBatch[2][i];
          tokenIds.push({
            id: Number(cardBatch[0][i]),
            cardType: cardType,
            image: isMutant
              ? `mutantsvgs/${cardType}.svg`
              : `cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error(e);
      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    }
  };

  const onCardClick = async (item: Card) => {
    setSelectedCard(item);
    setOpenModal(true);
    let data: any = await cardContract?.categories(item.cardType);
    let circulatingSupplyData =
      Number(data[1]) - Number(data[0]) - Number(data[2]);
    setCirculatingSupply(circulatingSupplyData.toString());
    setBurnedSupply(data[2].toString());
  };

  const handleToAddressChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setToAddress(event.target.value.trim());
  };

  const transferNft = async (tokenId: number) => {
    if (!toAddress || !tokenId) return;
    try {
      setMainLoader(true);
      const transaction = await cardContract?.transferFrom(
        myAddress,
        toAddress,
        tokenId
      );
      await transaction.wait();
      notify("Transfer of card is successful.", "success");
      window.location.reload();
    } catch (e) {
      console.error("Error transferring NFT:", e);
      notify("Failed to transfer card", "error");
      setMainLoader(false);
    }
  };

  useEffect(() => {
    getAllCards();
  }, []);

  useEffect(() => {
    if (isConnected && myAddress) {
      getAllCards();
    }
  }, [isConnected, myAddress, getAllCards]);

  return (
    <div className="yourCards container">
      <Title title="My Cards" />

      <div className="bigger font here">
        {/* Your Deck is currently Tier:{" "} */}
        {/* <div className="filterIcons">
          {tier === 11 ? (
            <img src={t1} alt="Badge" className="badge" />
          ) : tier === 12 ? (
            <img src={t2} alt="Badge" className="badge" />
          ) : (
            <img src={t3} alt="Badge" className="badge" />
          )}{" "}
        </div> */}
        {/* <br></br>
        <br></br> The Average Strength of your Deck is {avgstrength} */}
      </div>
      <ProgressDemo max={500} currentValue={avgstrength} />

      <div className="filterContainer">
        <div className="filterCards">
          <div className="filterIcons">
            <div
              onClick={() => handleRarityClick(0)}
              className={selectedRarity === 0 ? "selected_type" : ""}
            >
              <img src={squareIcon} alt="" />
            </div>
            <div
              onClick={() => handleRarityClick(1)}
              className={selectedRarity === 1 ? "selected_type" : ""}
            >
              <img src={triangleIcon} alt="" />
            </div>
            <div
              onClick={() => handleRarityClick(2)}
              className={selectedRarity === 2 ? "selected_type" : ""}
            >
              <img src={circleIcon} alt="" />
            </div>
            <div
              onClick={() => handleRarityClick(3)}
              className={selectedRarity === 3 ? "selected_type" : ""}
            >
              <img src={rectangleIcon} alt="" />
            </div>
          </div>
          <div className="filterIcons">
            <div
              onClick={() => handleTypeClick(0)}
              className={selectedType === 0 ? "selected_type" : ""}
            >
              <img src={character} alt="" />
            </div>
            <div
              onClick={() => handleTypeClick(1)}
              className={selectedType === 1 ? "selected_type" : ""}
            >
              <img src={weapon} alt="" />
            </div>
            <div
              onClick={() => handleTypeClick(2)}
              className={selectedType === 2 ? "selected_type" : ""}
            >
              <img src={shield} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="totalCards">
        {filteredData.length}/{total}
      </div>
      <div className="container">
        {filteredData.map((item, index) => (
          <img
            src={item.image}
            alt={item.image + ""}
            key={index}
            className="transition-all card hover:-translate-y-2"
            onClick={() => {
              onCardClick(item);
            }}
          />
        ))}
      </div>

      {openModal && selectedCard && (
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <div className="relative modal-body">
            {!transferCard ? (
              <>
                <div className="picoCardBg">
                  <img src={selectedCard.image} alt="" className="mx-auto" />
                </div>
                <p className="cardNumber">#{selectedCard.id.toString()}</p>
                <p className="circulating text-white">
                  {circulatingSupply} Circulating | {burnedSupply} - Burned
                </p>

                <div className="">
                  <PrimaryButton
                    text={`Transfer`}
                    width={`244px`}
                    onClick={() => setTransferCard(true)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="picoCardBg">
                  <img src={selectedCard.image} alt="" className="mx-auto" />
                </div>
                <div className="transferCard">
                  <label className="">Transfer to</label>
                  <input
                    type="text"
                    className="inputStyle"
                    placeholder="0x(address)"
                    value={toAddress || ""}
                    onChange={handleToAddressChange}
                  />
                </div>
                <div className="actionButtons">
                  <PrimaryButton
                    text={`Confirm`}
                    width={`244px`}
                    onClick={() => transferNft(selectedCard.id)}
                  />
                  <PrimaryButton
                    text={`Cancel`}
                    width={`244px`}
                    onClick={() => setTransferCard(false)}
                  />
                </div>
              </>
            )}
          </div>
          <div className="closeModal" onClick={() => setOpenModal(false)}>
            x
          </div>
        </Modal>
      )}
    </div>
  );
};

export default YourCards;
