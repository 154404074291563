import React, { useState, useEffect, useCallback } from "react";
import "./HostGame.scss";
import Title from "../../Components/Common/Title/Title";
import ToggleSwitch from "../../Components/Common/UI/ToggleSwitch/ToggleSwitch";
import { Link, useNavigate } from "react-router-dom";
import PrimaryButton from "../../Components/Common/UI/PrimaryButton/PrimaryButton";
import HostInput from "../../Components/Common/UI/HostInput/HostInput";
import RulesAndRisk from "../../Components/RulesAndRisk/RulesAndRisk";
import AllowancePopup from "../../Components/Common/AllowancePopup/AllowancePopup";
import AllowanceModal from "../../Components/Modals/AllowanceModal/AllowanceModal";
import { useWeb3 } from "../../web3/Web3Context";
import cardMetadata from "../../web3/cardMetadata.json";

import { ethers } from "ethers";
import { notify } from "../../utils/customToast";
import InfoModal from "../../Components/Common/Modals/InfoModal/InfoModal";

import { waitRefresh } from "../../utils/waitRefresh";
import {
  cardContract,
  sfortContract,
  pico1v1Contract,
  grabberContract,
} from "../../web3/contracts";
import BorderStyle from "../../Components/Common/BorderStyle/BorderStyle";
import Loader from "../../Components/Common/Loader/Loader";

type Props = {};

const HostGame: React.FC<Props> = () => {
  const { myAddress, isConnected } = useWeb3(); // Only get web3-related state here
  const [isRulesConfirmed, setRulesConfirmed] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [warningIsOpen, setwarningIsOpen] = useState(false);
  const [ratiowarningIsOpen, setratiowarningIsOpen] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [weaponCount, setWeaponCount] = useState(0);
  const [shieldCount, setShieldCount] = useState(0);
  const [notEnoughtSFort, setNotEnoughtSFort] = useState<boolean>(false);
  const [balanceAllowance, setBalanceAllowance] = useState<string>("0");
  const [balance, setBalance] = useState<string>("0");
  const [approveAmt, setApproveAmt] = useState<string>("0");
  const [betAmount, setBetAmount] = useState<string>("0");
  const [mainLoader, setMainLoader] = useState<boolean>(false);
  const [errorWhileHosting, setErrorWhileHosting] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");
  const [ratioWarningContent, setRatioWarningContent] =
    useState<any>(undefined);
  // const [percentage, setPercentage] = useState<number>(0);
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false);
  const [totalRarity, setTotalRarity] = useState<any>(undefined);
  const [rarityTotalNumber, setRarityTotalNumber] = useState<any>(undefined);
  const [rarityPercentages, setRarityPercentages] = useState<{
    rarity0: number;
    rarity1: number;
    rarity2: number;
  }>({ rarity0: 0, rarity1: 0, rarity2: 0 });
  const [cardsTypeInPercent, setCardsTypeInPercent] = useState({
    characterPercent: 0,
    weaponPercent: 0,
    shieldPercent: 0,
  });
  const navigate = useNavigate();

  const handleToggleChange = async (checked: boolean) => {
    if (!cardContract || !pico1v1Contract || !myAddress) return;

    try {
      setMainLoader(true);
      const tx = await cardContract.setApprovalForAll(
        pico1v1Contract.address,
        checked
      );
      await tx.wait();
      setIsApprovedForAll(checked);
      notify(
        checked
          ? "Approval granted successfully!"
          : "Approval revoked successfully!",
        "success"
      );
      //waitRefresh(3);
    } catch (error) {
      console.error("Error during setApprovalForAll:", error);
      //   notify(
      //     "An error occurred while changing approval status. Please try again.",
      //     "error"
      //   );
      setErrorWhileHosting(true);
      setErrorDescription(
        "An error occurred while changing approval status. Please try again."
      );
    } finally {
      setMainLoader(false);
    }
  };

  const onConfirmRules = () => {
    setRulesConfirmed(true);
  };

  const onCloseAllowanceModal = () => {
    setIsOpen(false);
  };

  const fetchBalance = useCallback(async () => {
    if (!sfortContract || !myAddress) return;
    try {
      const balance = await sfortContract.balanceOf(myAddress);
      setBalance(
        Math.floor(Number(ethers.utils.formatEther(balance))).toString()
      ); // Setting the balance

      await fetchRarityPercentages();
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  }, [sfortContract, myAddress]);

  const getUserAllowance = useCallback(async () => {
    if (!sfortContract || !myAddress || !pico1v1Contract) return;
    setMainLoader(false);
    try {
      const allowance = await sfortContract.allowance(
        myAddress,
        pico1v1Contract.address
      );
      const formattedAllowance = ethers.utils.formatEther(allowance);
      setBalanceAllowance(
        Number(formattedAllowance) > 0 ? formattedAllowance : ""
      );
    } catch (e) {
      console.error("Error fetching allowance:", e);
    } finally {
      setMainLoader(false);
      setIsOpen(false);
    }
  }, [sfortContract, myAddress, pico1v1Contract]);

  const checkApprovalForAll = useCallback(async () => {
    if (!cardContract || !pico1v1Contract || !myAddress) return;
    try {
      const isApproved = await cardContract.isApprovedForAll(
        myAddress,
        pico1v1Contract.address
      );
      setIsApprovedForAll(isApproved);
    } catch (error) {
      console.error("Error checking approval status:", error);
    }
  }, [cardContract, pico1v1Contract, myAddress]);

  const handleBetAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBetAmount(event.target.value);
  };

  const onApprove = async () => {
    if (!sfortContract || !pico1v1Contract) {
      //   notify("Smart contract is not available.", "error");
      setErrorWhileHosting(true);
      setErrorDescription("Smart contract is not available.");
      return;
    }

    if (Number(approveAmt) <= 0) {
      //   notify("Please choose a valid allowance.", "error");
      setErrorWhileHosting(true);
      setErrorDescription("Please choose a valid allowance.");
      return;
    }

    try {
      const amountBn = ethers.utils.parseUnits(approveAmt);
      setMainLoader(true);

      const tx = await sfortContract.approve(pico1v1Contract.address, amountBn);
      await tx.wait();

      notify("Allowance added successfully!", "success");
      getUserAllowance();
    } catch (error) {
      console.error("Error during approval:", error);
      //   notify("An error occurred. Please try again.", "error");
      setErrorWhileHosting(true);
      setErrorDescription("An error occurred. Please try again.");
    } finally {
      setMainLoader(false);
    }
  };

  const onHostGame = async () => {
    if (cardsTypeInPercent.characterPercent > 50) {
      console.log("More than 50% characters");
      setRatioWarningContent(
        <p className="cardsRatioInfoCircles">
          You have too many <span>caracters</span> in your deck. You have more
          than <span> {cardsTypeInPercent.characterPercent}%</span>. No more
          then <span>50%</span> characters allowed.
        </p>
      );
      setwarningIsOpen(true);
    } else {
      if (totalRarity?.circles?.total > totalRarity?.circles?.max) {
        console.log("CIRCLE RARITY");
        setRatioWarningContent(
          <p className="cardsRatioInfoCircles">
            You have too many <span>founders</span> in your crypto army. You
            have <span> {rarityPercentages.rarity2}%</span> circle cards in your
            deck. No more then <span>33%</span> circles allowed.
          </p>
        );
        setwarningIsOpen(true);
      } else if (totalRarity?.squares?.total < totalRarity?.squares?.min) {
        console.log("CIRCLE RARITY");
        setRatioWarningContent(
          <p className="cardsRatioInfoSquares">
            Your <span>community</span> is too small. You have{" "}
            <span>{rarityPercentages.rarity0}%</span> sqaure cards in your deck.
            You can only play with <span>33%</span> squares.
          </p>
        );
        setwarningIsOpen(true);
      } else {
        setRatioWarningContent(undefined);
        if (Number(balanceAllowance) < Number(betAmount)) {
          console.log("not enought amount");
          setNotEnoughtSFort(true);
        } else {
          if (
            !sfortContract ||
            !pico1v1Contract ||
            !cardContract ||
            !myAddress
          ) {
            // notify("Smart contracts are not available.", "error");
            setErrorWhileHosting(true);
            setErrorDescription("Smart contracts are not available.");
            return;
          }

          if (!(Number(betAmount) >= 10)) {
            // notify("Please choose an amount of at least 10 $FORT", "error");
            setErrorWhileHosting(true);
            setErrorDescription("Please choose an amount of at least 10 $FORT");
            return;
          }

          const balance = await cardContract?.balanceOf(myAddress);
          const cardCount = balance.toNumber();

          if (cardCount < 27) {
            setwarningIsOpen(true);
            return;
          }

          /*
				  if (cardCount < 18) {
					setratiowarningIsOpen(true);
					return;
				  }
			*/

          try {
            const betAmountInt = parseInt(betAmount, 10);
            console.log("HOST START");
            setMainLoader(true);

            const allowance = await sfortContract.allowance(
              myAddress,
              pico1v1Contract.address
            );
            if (allowance.lt(betAmountInt)) {
              //   notify(
              //     "Insufficient allowance. Please increase your allowance.",
              //     "error"
              //   );
              setErrorWhileHosting(true);
              setErrorDescription(
                "Insufficient allowance. Please increase your allowance."
              );
              setMainLoader(false);
              return;
            }

            let isApprovedForAll = await cardContract.isApprovedForAll(
              myAddress,
              pico1v1Contract.address
            );
            if (!isApprovedForAll) {
              const tx = await cardContract.setApprovalForAll(
                pico1v1Contract.address,
                true
              );
              await tx.wait();
            }
            const estimatedGas = await pico1v1Contract?.estimateGas.createRoom(
              betAmountInt
            );
            const gasLimit = Math.floor(estimatedGas.toNumber() * 1.2);
            const overrides = { gasLimit: ethers.utils.hexlify(gasLimit) };
            const tx = await pico1v1Contract?.createRoom(
              betAmountInt,
              overrides
            );
            await tx.wait();

            notify("Game hosted successfully!", "success");
            setMainLoader(false);
            // Navigate to waiting room or other logic
            navigate("/awaiting-opponent");
          } catch (error) {
            console.error("Error hosting game:", error);
            setErrorWhileHosting(true);
            setErrorDescription(
              "An error occurred while hosting the game. Please try again."
            );
            setMainLoader(false);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isConnected && myAddress) {
      getUserAllowance();
      fetchCardCounts();
      checkApprovalForAll();
      fetchBalance();
    }
  }, [isConnected, myAddress, getUserAllowance, checkApprovalForAll]);

  const fetchCardCounts = useCallback(async () => {
    if (myAddress && cardContract) {
      try {
        let characterCount = 0;
        let weaponCount = 0;
        let shieldCount = 0;

        const balance = await cardContract.balanceOf(myAddress);
        await fetchRarityPercentages();
        const tokenIds: any[] = [];
        if (balance && balance.gt(0)) {
          await getCardsBatch(tokenIds, 0, balance.toNumber());
        }
        console.log("tokenIds", tokenIds);
        tokenIds.forEach((card: any) => {
          const metadata = cardMetadata.find(
            (meta) => meta.id === card.cardType
          );
          if (metadata) {
            switch (metadata.type) {
              case 0:
                characterCount++;
                break;
              case 1:
                weaponCount++;
                break;
              case 2:
                shieldCount++;
                break;
            }
          }
        });
        const totalCount = characterCount + weaponCount + shieldCount;

        // Calculate percentages
        const characterPercent = (characterCount / totalCount) * 100;
        const weaponPercent = (weaponCount / totalCount) * 100;
        const shieldPercent = (shieldCount / totalCount) * 100;
        setCardsTypeInPercent({
          characterPercent: Number(characterPercent.toFixed(2)),
          weaponPercent: Number(weaponPercent.toFixed(2)),
          shieldPercent: Number(shieldPercent.toFixed(2)),
        });
        setCharacterCount(characterCount);
        setWeaponCount(weaponCount);
        setShieldCount(shieldCount);
      } catch (e) {
        console.error("Failed to fetch card counts", e);
      }
    }
  }, [myAddress]);

  console.log("cardssss", cardsTypeInPercent);
  const getCardsBatch = async (
    tokenIds: any[],
    from: number,
    maxLength: number
  ) => {
    if (!cardContract) return;
    try {
      const countPerTrait = await grabberContract?.getCardsBatch(
        myAddress,
        from
      );
      for (let i = 0; i < countPerTrait[0].length; i++) {
        const cardType = Number(countPerTrait[1][i]);
        const metadata = cardMetadata.find((meta) => meta.id === cardType);
        if (metadata) {
          tokenIds.push({
            id: countPerTrait[0][i],
            cardType: cardType,
            image: `/assets/images/cardsvgs/${cardType}.svg`,
            name: metadata.name,
            rarity: metadata.rarity,
            cattype: metadata.type,
            strength: metadata.strength,
          });
        }
      }

      if (from + 40 < maxLength) {
        await getCardsBatch(tokenIds, from + 40, maxLength);
      }
    } catch (e) {
      console.error("Error fetching card batch", e);
    }
  };

  // Function to fetch and calculate rarity percentages
  const fetchRarityPercentages = async () => {
    const totalRarityCounts = { rarity0: 0, rarity1: 0, rarity2: 0 };
    let totalCards = 0;
    const balance = await cardContract?.balanceOf(myAddress); // Get the total balance of cards in the wallet

    const batches = Math.ceil(balance / 40); // Calculate how many batches of 40 cards to process

    // Loop through the batches and count rarities
    for (let i = 0; i < batches; i++) {
      const from = i * 40;
      const cardBatch = await grabberContract?.getCardsBatch(myAddress, from);

      const categoryIds = cardBatch[1]; // Assume the second array is the categoryIDs
      totalCards += categoryIds.length;

      // Loop through the categoryIds and fetch metadata for rarity
      for (const categoryId of categoryIds) {
        const metadata = cardMetadata.find(
          (meta: any) => meta.id === Number(categoryId)
        );

        if (metadata) {
          const rarity = metadata.rarity;

          // Increment the count for each rarity
          if (rarity === 0) {
            totalRarityCounts.rarity0++;
          } else if (rarity === 1) {
            totalRarityCounts.rarity1++;
          } else if (rarity === 2) {
            totalRarityCounts.rarity2++;
          }
        }
      }
    }
    setRarityTotalNumber(totalRarityCounts);

    // Calculate total number of cards
    const total =
      totalRarityCounts.rarity0 +
      totalRarityCounts.rarity1 +
      totalRarityCounts.rarity2;
    // Calculate minimum and maximum thresholds for rarity0 and rarity2 percentages
    const minSquaresPercentage = 33.333; // minimum 33%
    const maxCirclesPercentage = 33.333; // maximum less than 33%
    // Calculate the number of cards based on percentages
    const minSquaresCards = Math.ceil((minSquaresPercentage / 100) * total); // minimum 33% of total
    const maxCirclesCards = Math.floor((maxCirclesPercentage / 100) * total); // less than 33% of total

    // Calculate max allowed for rarity0 based on remaining cards after minRarity1 and maxRarity2
    const minTrianglesCards = Math.ceil((10 / 100) * total); // minimum 10% of total for rarity1
    // const minRarity2Cards = Math.ceil((10 / 100) * total); // minimum 10% of total for rarity2
    // const maxRarity0Cards = total - minRarity1Cards - minRarity2Cards;
    // Ensure rarity0 does not exceed 80% of total
    // const maxAllowedRarity0 = Math.floor((80 / 100) * total);
    // const finalMaxRarity0Cards = Math.min(maxRarity0Cards, maxAllowedRarity0);
    setTotalRarity({
      squares: displayRarities(
        "squares",
        totalRarityCounts.rarity0,
        minSquaresCards,
        total
      ),
      triangles: displayRarities(
        "triangles",
        totalRarityCounts.rarity1,
        minTrianglesCards,
        total
      ),
      circles: displayRarities(
        "circles",
        totalRarityCounts.rarity2,
        maxCirclesCards,
        total
      ),
    });

    // Calculate percentages
    const rarity0Percent = (totalRarityCounts.rarity0 / totalCards) * 100;
    const rarity1Percent = (totalRarityCounts.rarity1 / totalCards) * 100;
    const rarity2Percent = (totalRarityCounts.rarity2 / totalCards) * 100;

    setRarityPercentages({
      rarity0: parseFloat(rarity0Percent.toFixed(2)),
      rarity1: parseFloat(rarity1Percent.toFixed(2)),
      rarity2: parseFloat(rarity2Percent.toFixed(2)),
    });
  };

  function displayRarities(
    rarity: any,
    value: any,
    minRarity: any,
    total: any
  ) {
    if (rarity === "squares") {
      return {
        total: value,
        min: minRarity,
      };
    }
    if (rarity === "circles") {
      return {
        total: value,
        max: minRarity,
      };
    }
    return { total: value };
  }

  const checkCircleMaxCardsNumber = (cards: any) => {
    return cards?.circles?.total > cards?.circles?.max ? "error" : "";
  };
  const checkCircleMinCardsNumber = (cards: any) => {
    return cards?.squares?.total < cards?.squares?.min ? "error" : "";
  };

  return (
    <>
      {isRulesConfirmed ? (
        <div className="hostGame">
          <Title title="HOST A ROOM" />
          <BorderStyle />
          <div className="subTitle">PLAY A 1 VS 1 PICO GAME</div>
          <div className="hostGameDescription">
            <p>
              Please make sure you first grant card access to the game, and be
              aware of the room fee, this fee will be redeemed only by the
              winning player.
            </p>

            {/* <div className="rarityInPercentages">
              <span>Squares: {rarityPercentages.rarity0}%</span>
              <span>Triangles: {rarityPercentages.rarity1}%</span>
              <span>Circles: {rarityPercentages.rarity2}%</span>
            </div> */}
            <p className="cardsInfo">
              You own <b>{characterCount}</b> characters, <b>{weaponCount}</b>{" "}
              weapons, and <b>{shieldCount}</b> shields. Of which{" "}
              <span className={`${checkCircleMinCardsNumber(totalRarity)}`}>
                {rarityTotalNumber?.rarity0} / {totalRarity?.squares.min}{" "}
                squares,
              </span>{" "}
              <span>{rarityTotalNumber?.rarity1}</span> triangles,{" "}
              <span className={`${checkCircleMaxCardsNumber(totalRarity)}`}>
                {totalRarity?.circles?.total} / {totalRarity?.circles.max}{" "}
                circles
              </span>
            </p>
          </div>
          <HostInput value={betAmount} onChange={handleBetAmountChange} />

          <ToggleSwitch
            checked={isApprovedForAll}
            onChange={handleToggleChange}
            title="CARD ACCESS:"
          />

          <div className="actionButtons">
            <PrimaryButton text="HOST ROOM" onClick={onHostGame} />
            <Link to="/">
              <PrimaryButton text="Back" />
            </Link>
          </div>
          <AllowancePopup
            title="Game Room Allowance"
            balanceAllowance={Number(balanceAllowance)}
            onClickAdd={() => setIsOpen(true)}
          />
          <AllowanceModal
            open={modalIsOpen}
            onClose={onCloseAllowanceModal}
            userTokenBalance={Number(balance)}
            balance={Number(balance)}
            approveAmt={approveAmt}
            setApproveAmt={setApproveAmt}
            onApprove={onApprove}
          />
          <InfoModal
            openModal={warningIsOpen}
            closeModal={() => setwarningIsOpen(false)}
            handleBack={() => setwarningIsOpen(false)}
            title="YOU NEED A FULL DECK"
            description={
              ratioWarningContent
                ? ratioWarningContent
                : "To play PiCO you need a minimum of 18 cards. To complete your deck click on the card shop button below."
            }
            mainButtonText={"CARD SHOP"}
            hideMainButton={false}
            handleMainButton={() => navigate("/card-shop")}
          />
          <InfoModal
            openModal={ratiowarningIsOpen}
            closeModal={() => setratiowarningIsOpen(false)}
            handleBack={() => setratiowarningIsOpen(false)}
            title="YOUR RATIOS ARE WRONG"
            description="To play PiCO you need a minimum of 33% Square Cards, and maximum of 33% Circle Cards."
            mainButtonText={"CARD SHOP"}
            hideMainButton={false}
            handleMainButton={() => navigate("/card-shop")}
          />

          <InfoModal
            openModal={notEnoughtSFort}
            closeModal={() => setNotEnoughtSFort(false)}
            handleBack={() => setNotEnoughtSFort(false)}
            title="NOT ENOUGH $FORT"
            description="You will need more $FORT to play PiCO, we recommend to hold at least 20 $FORT for fees."
            handleMainButton={() => {
              setNotEnoughtSFort(false);
              setIsOpen(true);
            }}
            hideMainButton={false}
            mainButtonText="ADD"
          />
          <InfoModal
            openModal={errorWhileHosting}
            closeModal={() => setErrorWhileHosting(false)}
            handleBack={() => setErrorWhileHosting(false)}
            title="ERROR"
            description={errorDescription}
          />
        </div>
      ) : (
        <RulesAndRisk
          onClick={onConfirmRules}
          title="Rules & Risks"
          description={[
            "At the start of each match, 9 of your PiCO cards will be transferred into the smart contract. All cards, except those burned, will be returned to the players at the end of the match.",
            "Defeated cards are burned permanently and will not be returned at the end of the match. Players have 60 seconds to pick a card per turn. If a player does not make a selection within this time, a random card will be chosen for them. The game room fee will be returned only to the winning player. The losing player will also lose their fees.",
            "By clicking confirm, you acknowledge and accept these terms and the associated risks.",
          ]}
        />
      )}
      {mainLoader && <Loader />}
    </>
  );
};

export default HostGame;
